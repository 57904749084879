import { FindFolderIdByPath } from './FolderHelper'

export const isValidCellValue = (field, value, blueprintState, stateList, frequencyList, account, folders) => {
  if (field === 'isValid' || field === 'id' || field === 'product_id') {
    return true
  }
  const index = blueprintState.findIndex((item) => item.value === field)
  const myField = blueprintState[index]
  const type = myField.type
  const key = myField.value
  if (key === 'frequency' || key === 'name' || key === 'folder' || key === 'state') {
    if (value === null || value === '' || value === 'null' || value === 'value required') {
      return false
    }
  }
  if (key === 'frequency' || key === 'tags' || key === 'folder' || key === 'state' || key === 'certifications') {
    return checkValue(value, key, stateList, frequencyList, account, folders)
  } else {
    if (value === null || value === '' || value === 'null') {
      return true
    }
    switch (type) {
      case 'number':
      case 'money':
        return !isNaN(value)
        break
      case 'integer':
        return isInt(value)
        break
      case 'string':
        if (key === 'id') {
          return true
        }
        return typeof value === 'string'
        break
      case 'date':
        let date
        if (typeof value === 'string') {
          return isValidDatePattern(value)
        } else {
          date = new Date(value)
          return !isNaN(date.getTime())
        }
        break
      default:
        return true
        break
    }
  }
}

export const checkValue = (value, key, stateList, frequencyList, account, folders) => {
  switch (key) {
    case 'product_id':
      return true
      break
    case 'tags':
    case 'certifications':
      if (value === null || value === '' || value === 'null') {
        return true
      }
      const arr = value.split(',')
      return Array.isArray(arr)
      break
    case 'folder':
      if (!value || value === '' || value === 'value required') return false
      const normalizedPath = value.startsWith('/') ? value : `/${value}`
      const path = `/${account.name}${normalizedPath}`
      return FindFolderIdByPath(path, { id: 'root', name: account.name, children: folders }) !== null
    case 'state':
      if (value === null) return false
      return stateList.data.some((item) => item.value === value.toLowerCase())
      break
    case 'frequency':
      if (value == null) return false
      return frequencyList.data.some((item) => item.value === value.toLowerCase())
      break
  }
}

export const checkType = (key, blueprintState) => {
  if (key === 'isValid' || key === 'product_id') {
    return 'string'
  }
  if (key === 'id') {
    return 'number'
  }
  if (key === 'frequency' || key === 'state' || key === 'folder') {
    return 'singleSelect'
  } else {
    const index = blueprintState.findIndex((item) => item.value === key)
    const type = blueprintState[index].type
    if (type === 'date') return 'date'
    if (type === 'number' || type === 'money') return 'number'
    if (type === 'integer') return 'integer'
    else {
      return 'string'
    }
  }
}

export const isValidDatePattern = (inputString) => {
  const pattern = /^\d{4}-\d{2}-\d{2}$/
  return pattern.test(inputString)
}

export const formatDateToString = (dateValue) => {
  if (!dateValue) {
    return null
  }
  try {
    // Check if dateValue is a valid date string
    if (!isNaN(Date.parse(dateValue))) {
      const myDate = new Date(dateValue)
      const year = myDate.getFullYear()
      const month = (myDate.getMonth() + 1).toString().padStart(2, '0')
      const day = myDate.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    } else {
      // If dateValue is not a valid date, return null
      return 'not a valid date'
    }
  } catch (error) {
    // If any error occurs during date conversion, return null
    return 'not a valid date'
  }
}

export const convertStringToDate = (dateString, format) => {
  if (dateString === '' || !dateString) {
    return dateString
  }
  try {
    const parts = dateString.split('/')
    let year, month, day

    switch (format) {
      case 'dd/MM/yy':
        year = parseInt(parts[2], 10) + 2000
        month = parseInt(parts[1], 10) - 1
        day = parseInt(parts[0], 10)
        return new Date(year, month, day)
        break
      case 'yyyy-mm-dd':
        ;[year, month, day] = dateString.split('-').map(Number)
        return new Date(year, month - 1, day)
      default:
        throw new Error(`Unsupported date format: ${format}`)
    }
  } catch (error) {
    return dateString
  }
}

export const isUUID = (str) => {
  if (str && typeof str === 'string') {
    const uuidRegex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/
    return uuidRegex.test(str)
  } else return false
}

export const findNameById = (list, id) => {
  const item = list.find((item) => item.id === id)
  return item && item.value && item.value !== '' ? item.value : 'value required'
}

export const returnId = (list, value) => {
  const index = value ? list.findIndex((item) => item.value.toLowerCase() === value.toLowerCase()) : -1
  return index !== -1 ? list[index].id : value
}

export const isInt = (value) => {
  if (isNaN(value)) {
    return false
  }
  const x = parseFloat(value)
  return (x | 0) === x
}
