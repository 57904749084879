export const FindFolderIdByPath = (path, folderTree) => {
  if (path.includes('root')) {
    return null
  }
  const normalizedPath = path.startsWith('/') ? path : `/${path}`

  const pathArray = normalizedPath.split('/').filter((part) => part !== '')

  const findFolderRecursively = (node, pathArray) => {
    if (!node) {
      return null
    }

    if (node.name === pathArray[0]) {
      if (pathArray.length === 1) {
        return node.id
      } else if (node.children && node.children.length > 0) {
        return findFolderRecursively(
          node.children.find((child) => child.name === pathArray[1]),
          pathArray.slice(1)
        )
      }
    }
    return null
  }

  return findFolderRecursively(folderTree, pathArray)
}

export const constructFolderPathById = (folderTree, folderId) => {
  function findFolderRecursively(node, targetId, currentPath) {
    if (node.id === targetId) {
      return currentPath
    } else if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        const path = findFolderRecursively(child, targetId, currentPath + '/' + child.name)
        if (path) {
          return path
        }
      }
    }
    return null
  }

  const path = findFolderRecursively(folderTree, folderId, '')
  return path ? path : folderId
}
