import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { DragEvent } from 'react'

type Props = {
  onFileUpload
  onFileName
  onFile
}

const CSVUpload = (props: Props) => {
  const { onFileUpload, onFileName, onFile } = props
  const [dragIsOver, setDragIsOver] = useState<boolean>(false)
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragIsOver(true)
  }

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragIsOver(false)
  }

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragIsOver(false)
    handleFileChange(event, 'drag')
  }
  const handleFileChange = (event, drag?) => {
    let file
    if (drag) {
      file = event.dataTransfer.files[0]
    } else {
      file = event.target.files[0]
    }

    if (file) {
      const fileName = file.name.toLowerCase()
      if (fileName.endsWith('.csv')) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const contents = e.target.result
          onFileUpload(contents)
          onFile(file)
          onFileName(fileName)
        }
        reader.readAsText(file)
      } else {
        onFileUpload(null)
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '70%',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        border: '2px dashed #007AFF',
        marginTop: '16px',
        textAlign: 'center',
        backgroundColor: dragIsOver ? '#E4E7EB' : 'white'
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}>
      <div>
        <p style={{ textAlign: 'center', fontWeight: '600px' }}>Upload .csv file</p>
        <input
          color="primary"
          accept=".csv"
          type="file"
          onChange={handleFileChange}
          id="icon-button-file"
          style={{ display: 'none' }}
        />
        <label htmlFor="icon-button-file">
          <Button
            variant="contained"
            component="span"
            size="large"
            color="primary"
            sx={{ fontSize: '16px', fontFamily: 'Inter', textTransform: 'none' }}>
            Select File
          </Button>
        </label>
      </div>
    </Box>
  )
}

export default CSVUpload
