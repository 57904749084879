import {
  ContentWrapper,
  Box,
  FlexRow,
  ButtonSecondary,
  ButtonPrimary,
  Stack,
  ContentScrolling,
  AlertError,
  Loading
} from '@papertrail/styleguide'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CSVUpload from './CSVUpload'
import { parseCSV } from './ParseCSV'
import ImportSteps from './importSteps'
import { MatchColumns } from './MatchColumns'
import { UploadRow } from './UploadRow'
import { ErrorRow } from './ErrorRow'
import { ValidateData } from './validateData'
import { useApiGet, useApiPatch, useApiPost, downloadCSV, EventManager } from '@papertrail/web3-utils'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { AddToPapertrail } from './addToPapertrail'
import { Blueprint } from 'src/types'
import { blueprintMapper } from './BlueprintHelper'
import { setIds } from './ImportHelper'
import { useSessionAccount } from '@papertrail/web3-session'
import { uploadImport, useApiGetFolders } from './hooks'
import { debounce } from '@mui/material'
import { HelperText } from '../HelperText'

type Params = {
  accountid: string
}

type Props = {
  Import
  loaded: () => void
  exit: () => void
  setPagination
  setErrorsOnly
  refreshGet: () => void
  pagination
  onEdit
  mapping
}

export const mapProductsResponse = (data) => {
  const result = {}

  Object.entries(data).forEach(([productId, rowIds]) => {
    ;(rowIds as number[]).forEach((rowId) => {
      if (!result[rowId]) {
        result[rowId] = []
      }
      result[rowId].push(productId)
    })
  })
  return result
}

export const Mapper = (data) => {
  const arr = data.data.map((item) => {
    return {
      value: item.name.toLowerCase(),
      label: item.name,
      id: item.id
    }
  })
  arr.sort((a, b) => a.name - b.name)
  return arr
}

export function ImportData(props: Props) {
  const { loaded, exit, Import, setPagination, setErrorsOnly, refreshGet, pagination, onEdit, mapping } = props
  const { accountid } = useParams() as Params
  const [parsedData, setParsedData] = useState([])
  const [matchedData, setMatchedData] = useState([])
  const [validatedData, setValidatedData] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataForSubmission, setDataForSubmission] = useState([])
  const rowUpdatesRef = useRef([])
  const [importName, setImportName] = useState<string>('')
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [errorRow, showErrorRow] = useState<boolean>(false)
  const [fileName, setFileName] = useState<string>('')
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [blueprintState, loadFields] = useApiGet<Blueprint>(`/accounts/${accountid}`, blueprintMapper)
  const [frequencyList, loadFrequencies] = useApiGet(`/accounts/${accountid}/frequencies`, (data) => {
    const frequencies = Mapper(data)
    return frequencies
  })
  const [stateList, loadStates] = useApiGet<any[]>(`/accounts/${accountid}/states`, (data) => {
    const states = Mapper(data)
    return states
  })
  const account = useSessionAccount()

  const [columnData, setColumnData] = useState([])
  const [importId, setImportId] = useState<string>('')

  const [importCreatedState, createImport] = useApiPost(`/accounts/${accountid}/data_import`, (data) => data)
  const [importState, saveImport] = useApiPost(
    `/accounts/${accountid}/data_import/${
      importCreatedState && importCreatedState.data && importCreatedState.data.data.id
    }/initialise`,
    (data) => data
  )
  const [importValid, validateImport] = useApiPost(
    `/accounts/${accountid}/data_import/${importId}/validate`,
    (data) => data
  )
  const [folders, setFolders] = useState([])
  const rootFolderId = account ? account.rootFolderId : undefined
  const [folderList, loadFolders] = useApiGetFolders(accountid, rootFolderId, folders)
  const [Products, loadProducts] = useApiGet(`/accounts/${accountid}/data_import/${importId}/products`, (data) =>
    mapProductsResponse(data)
  )
  const [csvState, loadCSVState] = useApiPost(`/accounts/${accountid}/data_import/sample/csv`, (data) => data)
  const [importPatchState, patchImport] = useApiPatch(
    `/accounts/${accountid}/data_import/${importId}/rows/update`,
    (data) => data
  )
  const [importLoadState, loadImport] = useApiPost(
    `/accounts/${accountid}/data_import/${importId}/load`,
    (data) => data
  )
  const history = useHistory()
  const location = useLocation()
  const [matchingColumnsErr, setMatchingColumnsErr] = useState<boolean>(false)
  const [originalFile, setOriginalFile] = useState<string>('')
  const [currentState, setCurrentState] = useState<string>('')
  const [currentMapping, setMapping] = useState<any>(null)
  const [updatedMapping, updateMapping] = useApiPatch<any>(
    `/accounts/${accountid}/data_import/${importId}/mapping`,
    (data) => data
  )
  const [validationErrors, setValidationErrors] = useState(false)
  const [validateClicked, setValidateClicked] = useState(false)
  const [uploadResp, setUploadResp] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadFields({
      include: 'folder,folder.definitions,states,frequencies,filters,tags, flags,blueprint'
    })
  }, [])

  useEffect(() => {
    if (importId) {
      const listenerRef = EventManager.subscribe(['data_import_state_was_updated'], (data: any) => {
        console.log(data, 'event data')
        pusherUpdated(data)
      })
      return () => {
        EventManager.unsubscribe(listenerRef)
      }
    }
  }, [importId])

  const pusherUpdated = (data) => {
    if (data.uuid === importId) {
      if (data.state === 'Validated') {
        setIsFormValid(true)
        setValidationErrors(false)
        setValidateClicked(false)
        if (!Products.data) {
          loadProducts({})
        }
      }
      if (data.state === 'Validation Errors') {
        setIsFormValid(false)
        setValidationErrors(true)
        setValidateClicked(false)
      }
      setCurrentState(data.state)
    }
  }

  useEffect(() => {
    if (accountid) {
      if (!frequencyList.data && !stateList.data) {
        loadFrequencies({})
        loadStates({})
      }
    }
  }, [accountid])

  useEffect(() => {
    if (importCreatedState.isLoaded && accountid && originalFile && !uploadResp && !Import) {
      uploadImportFile()
    }
  }, [importCreatedState.isLoaded, accountid, originalFile])

  const uploadImportFile = async () => {
    try {
      const formData = new FormData()
      formData.append('file', originalFile)
      const resp = await uploadImport(accountid, formData, importCreatedState.data.data.id)
      setUploadResp(resp.data)
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  useEffect(() => {
    if (mapping) {
      setMapping(mapping)
    }
  }, [mapping])

  useEffect(() => {
    if (updatedMapping.isLoaded) {
      setTimeout(() => {
        setLoading(true)
        reset()
        refreshGet()
      }, 300)
    }
  }, [updatedMapping.isLoaded])

  useEffect(() => {
    if (account && !folderList.data) {
      loadFolders({ include: `descendants`, format: 'tree' })
    }
  }, [account])

  useEffect(() => {
    if (folderList.isLoaded) {
      setFolders(folderList.data)
    }
  }, [folderList])

  useEffect(() => {
    if (Import && Import.id && Import.data) {
      try {
        setParsedData(Import.data)
        setImportId(Import.id)
        setFileName(Import.name)
        setValidatedData(Import.data)
        setCurrentState(Import.status)

        if (Import.status && Import.status === 'Validated') {
          setIsFormValid(true)
        }
        if (Import.status && Import.status === 'Validation Errors') {
          setValidationErrors(true)
          setIsFormValid(false)
        }
        loadProducts({})
        setCurrentStep(Import.status === 'Validated' ? 3 : 2)
        setLoading(false)
      } catch (error) {
        console.log(error, 'error')
      }
    }
  }, [Import])

  useEffect(() => {
    if (
      parsedData &&
      parsedData.length > 0 &&
      !importId &&
      fileName &&
      stateList.isLoaded &&
      frequencyList.isLoaded &&
      account &&
      folders &&
      blueprintState.isLoaded &&
      currentMapping &&
      !importState.isLoading &&
      !importState.data &&
      !Import
    ) {
      saveImport({
        data_type: 'record',
        data: setIds(parsedData, stateList, frequencyList, account, folders, blueprintState.data.fields),
        mapping: currentMapping
      })
    }
  }, [parsedData, fileName, stateList, frequencyList, account, folders, blueprintState.data, currentMapping])

  useEffect(() => {
    if (importState.isLoaded && !importState.isError && importState.data.data.id && originalFile) {
      setIsFormValid(false)
      onEdit(importState.data.data)
      setLoading(true)
    }
  }, [importState.isLoaded])

  useEffect(() => {
    if (importState.isError) {
      setIsFormValid(false)
    }
  }, [importState.isError])

  useEffect(() => {
    if (importId) {
      updateQueryString()
      if (!importValid.isLoading && Import && currentState === 'Initial') {
        validateImport({})
      }
    }
  }, [importId])

  useEffect(() => {
    if (importLoadState.isLoaded) {
      removeQueryString()
      reset()
      loaded()
    }
  }, [importLoadState.isLoaded])

  useEffect(() => {
    if (importPatchState.isLoaded) {
      setDataForSubmission([])
      rowUpdatesRef.current = []
    }
  }, [importPatchState.isLoaded])

  const refresh = () => {
    setIsFormValid(false)
  }

  const saveUpdatedImportDebounced = useCallback(
    debounce((updatedData) => {
      patchImport({ data_type: 'record', rows: updatedData })
      if (currentStep !== 3) {
        setIsFormValid(false)
      }
    }, 500),
    [currentStep, patchImport, setIsFormValid]
  )
  const removeQueryString = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('importid')

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString()
    })
  }

  const updateQueryString = () => {
    const newQueryParams = new URLSearchParams(location.search)
    newQueryParams.set('importid', importId)

    history.push({
      pathname: location.pathname,
      search: `?${newQueryParams.toString()}`
    })
  }

  const handleFileUpload = (contents) => {
    showErrorRow(false)
    if (contents) {
      parseCSV(contents, setColumnData)
    } else {
      showErrorRow(true)
    }
  }

  const replaceKeysInArray = (dataArray, keysToReplace, newKeysArray) => {
    return dataArray.map((object) => {
      const newObj = {}

      Object.keys(object).forEach((key) => {
        const index = keysToReplace.indexOf(key)
        newObj[index !== -1 ? newKeysArray[index] : key] = object[key]
      })
      return newObj
    })
  }

  const combineArrays = (array1, array2) => {
    return array1.map((item, index) => {
      const key = item
      const value =
        array2[index].fieldName && array2[index].fieldName.length > 0 && array2[index].fieldName !== 'null'
          ? array2[index].fieldName
          : null
      return { column: key, blueprint_key: value }
    })
  }

  const updateParsedData = (newData, callback?) => {
    const currentFields =
      currentMapping && currentMapping.length > 0
        ? currentMapping.map((item) => item.column)
        : Object.keys(columnData[0]).map((key) => key)
    setMapping(combineArrays(currentFields, newData.fields))
    if (!importId) {
      const newKeysArray = newData.fields.map((field) => (field.userOption === 'match' ? field.fieldName : null))
      const updatedArray = replaceKeysInArray(columnData, currentFields, newKeysArray)
      // Remove columns where userOption is 'ignore'
      const filteredParsedData = updatedArray.map((object) => {
        const newObj = {}

        Object.keys(object).forEach((key) => {
          const index = newData.fields.findIndex((field) => field.fieldName === key)
          if (index !== -1 && newData.fields[index].userOption !== 'ignore') {
            newObj[key] = object[key]
          }
        })

        return newObj
      })

      callback(filteredParsedData)
    }
  }

  const newArrayWithoutIsValid = (data) => {
    return data.map((obj) => {
      const { product_match, ...rest } = obj
      return rest
    })
  }

  const goBack = () => {
    switch (currentStep) {
      case 0:
        reset()
        removeQueryString()
        exit()

        break

      case 1:
        reset()
        removeQueryString()
        exit()
        setCurrentStep(currentStep - 1)
        break

      case 2:
        setValidatedData([])
        setCurrentStep(currentStep - 1)
        break

      case 3:
        setCurrentStep(currentStep - 1)
        setIsFormValid(false)
        break

      default:
        setCurrentStep(currentStep - 1)
        break
    }
  }

  useEffect(() => {
    if (columnData && originalFile) {
      if (columnData && Array.isArray(columnData) && columnData.length > 0) {
        setIsFormValid(true)
      } else {
        showErrorRow(true)
      }
    }
  }, [columnData, originalFile])

  const reset = () => {
    setImportId('')
    setCurrentStep(0)
    setColumnData([])
    removeQueryString()
    setImportName('')
    setFileName('')
    setIsFormValid(false)
    setParsedData([])
    setOriginalFile('')
    setMatchedData([])
    setValidatedData([])
    setDataForSubmission([])
    setMatchingColumnsErr(false)
    showErrorRow(false)
    setMapping(null)
    setValidationErrors(false)
    setValidateClicked(false)
    setUploadResp(null)
  }

  const doKeysExist = (items) => {
    const keysToCheck = ['folder', 'name', 'state', 'frequency']
    return items.some((item) => keysToCheck.every((key) => item))
  }

  const handleDelete = () => {
    setColumnData([])
    setFileName('')
    setIsFormValid(false)
    setParsedData([])
    setOriginalFile('')
    setMatchingColumnsErr(false)
    showErrorRow(false)
  }

  const download = () => {
    loadCSVState({ data_type: 'record' })
  }

  useEffect(() => {
    if (csvState.isLoaded && csvState.data) {
      downloadCSV(csvState.data.data.download)
    }
  }, [csvState.isLoaded])

  const handleProcessRowUpdate = (newRow) => {
    setDataForSubmission((prevUpdates) => {
      const updates = prevUpdates.map((row) => (row.id === newRow.id ? newRow : row))
      if (!updates.find((row) => row.id === newRow.id)) {
        updates.push(newRow)
      }
      rowUpdatesRef.current = updates
      saveUpdatedImportDebounced(newArrayWithoutIsValid(updates))
      return updates
    })
    return newRow
  }

  const saveFile = async (file) => {
    if (!Import && !importId) {
      createImport({ data_type: 'record' })
    }
    setOriginalFile(file)
  }

  const validate = () => {
    validateImport({})
    setValidateClicked(true)
    setValidationErrors(false)
    setIsFormValid(false)
  }

  return (
    <ContentWrapper>
      <ContentScrolling>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Box width="50%">
            <p>{HelperText.popoverContents[currentStep]}</p>
          </Box>
          <Box width="50%">
            <ImportSteps currentStep={currentStep}></ImportSteps>
          </Box>
        </Stack>

        <Stack flexDirection="row" justifyContent={'right'}>
          <Box sx={{ background: 'white' }}>
            <ButtonSecondary onClick={() => goBack()}>
              {Import && currentStep === 1 ? 'Exit Import' : 'Back'}
            </ButtonSecondary>
          </Box>

          <Box sx={{ width: '10px' }}></Box>
          <Box sx={{ background: 'white' }}>
            <ButtonPrimary
              onClick={() => {
                setMatchingColumnsErr(false)
                if (currentStep === 3) {
                  loadImport({})
                  return
                }
                if (isFormValid) {
                  if (currentStep === 1 && currentMapping && currentMapping.length > 0) {
                    if (doKeysExist(currentMapping.map((item) => Object.keys(item)[0]))) {
                      if (Import && Import.id && importId) {
                        updateMapping(currentMapping)
                      } else {
                        setParsedData(matchedData)
                      }
                    } else {
                      setMatchingColumnsErr(true)
                      return
                    }
                  }
                  if (currentStep === 2) {
                    refreshGet()
                  }

                  setCurrentStep(currentStep + 1)

                  if (currentStep < 2) {
                    setIsFormValid(false)
                  }
                }
              }}
              disabled={!isFormValid && currentStep !== 3}
              data-testid="confirm">
              {currentStep !== 3 ? ' Next ' : 'Submit'}
            </ButtonPrimary>
          </Box>

          <Box sx={{ width: '10px', background: 'none' }}></Box>
        </Stack>
        <Box padding={0}>
          <ContentScrolling>
            {matchingColumnsErr && currentStep === 1 && (
              <AlertError>Required Fields for records are: Name, Folder, Frequency & State.</AlertError>
            )}
            {importPatchState.isError && currentStep > 1 && <AlertError>{importPatchState.error.errorCode}</AlertError>}
            {importLoadState.isError && currentStep === 3 && <AlertError>{importLoadState.error.errorCode}</AlertError>}
            {importState.isError && <AlertError>{importState.error.errorCode}</AlertError>}
            {validationErrors && currentStep === 2 && (
              <Box sx={{ maxHeight: '150px', overflow: 'hidden' }}>
                <ContentScrolling>
                  {' '}
                  <AlertError>{`There are data formatting errors in your import sheet, please use the toggle below to show rows with errors`}</AlertError>
                </ContentScrolling>
              </Box>
            )}
            {validateClicked === true && currentStep === 2 && (
              <Box sx={{ maxHeight: '150px', overflow: 'hidden' }}>
                <ContentScrolling>
                  {' '}
                  <AlertError>{`It can take a little while to validate your import sheet, you do not need to refresh the page. `}</AlertError>
                </ContentScrolling>
              </Box>
            )}
          </ContentScrolling>
        </Box>

        <FlexRow justifyContent="space-between" sx={{ marginLeft: '2px' }}>
          <div>{currentStep !== 0 && <h4>{fileName}</h4>}</div>
          <ButtonSecondary onClick={download}>Download Sample CSV</ButtonSecondary>{' '}
        </FlexRow>
        {currentStep === 0 && (
          <CSVUpload onFileUpload={handleFileUpload} onFile={saveFile} onFileName={(e) => setFileName(e)} />
        )}
        {currentStep === 0 && columnData && columnData.length > 0 && (
          <UploadRow
            uploadData={columnData}
            name={importName ? importName : fileName}
            handleDelete={handleDelete}></UploadRow>
        )}
        {currentStep === 0 && parsedData.length === 0 && errorRow === true && (
          <ErrorRow handleDelete={() => showErrorRow(false)}></ErrorRow>
        )}

        {currentStep === 1 &&
          ((columnData && columnData.length > 0) || (mapping && mapping.length > 0)) &&
          blueprintState.data &&
          blueprintState.data.fields && (
            <MatchColumns
              existingMapping={mapping ? mapping : null}
              isFormValid={(value) => setIsFormValid(value)}
              uploadData={columnData && columnData.length > 0 ? Object.keys(columnData[0]).map((key) => key) : null}
              blueprintState={blueprintState.data.fields}
              updatedData={(data: any) => updateParsedData(data, setMatchedData)}></MatchColumns>
          )}

        {currentStep === 2 &&
          parsedData &&
          parsedData.length > 0 &&
          blueprintState.data &&
          blueprintState.data.fields &&
          Import &&
          pagination && (
            <ValidateData
              status={currentState}
              setErrorsOnly={(data) => {
                setErrorsOnly(data)
                setValidationErrors(false)
                setValidateClicked(false)
              }}
              meta={{
                pagination: pagination,
                meta: Import.meta
              }}
              setPagination={setPagination}
              blueprintState={blueprintState.data.fields}
              rowData={parsedData}
              importId={importId}
              dataForSubmission={(data) => handleProcessRowUpdate(data)}
              loading={loading}
              frequencyList={frequencyList}
              stateList={stateList}
              folders={folders}
              refresh={refresh}
              validate={validate}></ValidateData>
          )}
        {(currentStep === 3 && (!Products.isLoaded || !validatedData || validatedData.length === 0)) ||
          (currentStep === 2 &&
            (!parsedData || parsedData.length === 0 || !Import || !pagination || !blueprintState.data || !importId) && (
              <Box>
                <Loading />
              </Box>
            ))}
        {currentStep === 3 && validatedData && Products.isLoaded && blueprintState.data && blueprintState.data.fields && (
          <AddToPapertrail
            meta={{
              pagination: pagination,
              meta: Import.meta,
              recordCount: Import.recordCount
            }}
            setPagination={setPagination}
            validatedData={validatedData}
            Products={Products.data}
            blueprintState={blueprintState.data.fields}
            updatedData={(data) => handleProcessRowUpdate(data)}
            loading={loading}></AddToPapertrail>
        )}
      </ContentScrolling>
    </ContentWrapper>
  )
}
