import { Box } from '@mui/system'
import { ToolTip } from '@papertrail/styleguide'
import React, { useState, useEffect } from 'react'
import { formatDateToString, isValidCellValue } from './CellHelper'

type Props = {
  gridProps
  blueprintState
  account
  folders
  stateList
  frequencyList
}

export const Cell = (props: Props) => {
  const [isValid, setisValid] = useState(true)
  const { gridProps, blueprintState, account, folders, stateList, frequencyList } = props

  useEffect(() => {
    if (gridProps) {
      setisValid(
        isValidCellValue(gridProps.field, gridProps.value, blueprintState, stateList, frequencyList, account, folders)
      )
    }
  }, [gridProps])

  const cellStyle = {
    backgroundColor: isValid ? 'inherit' : '#FDEBEC',
    height: '95%',
    width: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '0 10px',
    border: isValid ? 'none' : '1px solid #EB3745',
    boxShadow: isValid ? 'none' : '2px 0px 4px -2px rgba(0, 0, 0, 0.21)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const formatValue = () => {
    if (gridProps.colDef.type === 'date' && gridProps.value) {
      return formatDateToString(gridProps.value)
    }
    if (
      (gridProps.field === 'name' ||
        gridProps.field === 'field' ||
        gridProps.field === 'frequency' ||
        gridProps.field === 'state') &&
      !gridProps.value
    ) {
      return 'value required'
    } else return gridProps.value
  }

  const formatTooltip = () => {
    if (!isValid) {
      return `You have provided an incorrect ${gridProps.field} value`
    } else {
      return formatValue()
    }
  }

  return (
    <Box sx={cellStyle}>
      <ToolTip title={formatTooltip()}>
        <div>{formatValue()}</div>
      </ToolTip>
    </Box>
  )
}
